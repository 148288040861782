import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import addCostCenterMgmt from './addCostCenterMgmt';
import User from '../../admin/user';
import appStrings from '@/app/utility/string.utility';
// import itemCode from './itemCode';
export default {
  name: 'costCenterMgmt',
  components: {
    DatePicker,
    addCostCenterMgmt,
    User,
    // itemCode
  },
  watch: {
    currentPage: function() {
      this.getCostCenterGridData();
    },
    itemPerPage: function() {
      this.currentPage = 1;
      this.getCostCenterGridData();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      unsubscribe: null,
      loader: false,
      editMode: false,
      rgpNumber: '',
      createdBy: {
        text: null,
        value: null
      },
      itemCode: {
        text: null,
        value: null
      },
      costCenter: {
        text: null,
        value: null
      },
      wareHouse: {
        text: null,
        value: null
      },
      creationDate: [],
      showAddCostCenterMgmtModal: false,
      showUserModal: false,
      // showItemModal: false,
      costCenterMgmtFields: [
        {
          key: 'rgp_num',
          label: 'RGP Number'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creator_name'
        },
        {
          key: 'last_update_date',
          label: 'Last Updated Date'
        },
        {
          key: 'cost_center'
        },
        {
          key: 'item_code'
        },
        {
          key: 'item_desc',
          label: 'Item Description'
        },
        {
          key: 'cc_qty_sent',
          label: 'CC Quantity Sent'
        },
        {
          key: 'warehouse'
        },
      ],
      costCenterMgmtData: [],
      showValueSetModal: false,
      rgpDetails: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddCostCenterMgmtModal = true;
          this.rgpDetails = null;
        }
      }
    });
  },
  methods: {
    getCostCenterGridData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rgp_num: this.rgpNumber,
        created_by: this.createdBy.value,
        inv_item_id: this.itemCode.value,
        warehouse_id: this.wareHouse.value,
        cost_center_id: this.costCenter.value,
        creation_from: commonHelper.formattedDate(this.creationDate[0]),
        creation_to: commonHelper.formattedDate(this.creationDate[1])
      };
      this.loader = true;
      this.$store
        .dispatch('rgpManagement/getCostCenterGridData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.costCenterMgmtData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      // To Open Value Set Modal
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      // To Selected Value from value set modal
      if (this.vsetCode === appStrings.VALUESETTYPE.COST_CENTER_LIST) {
        this.costCenter = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.WAREHOUSE_LIST) {
        this.wareHouse = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RGP_INV_ITEMS) {
        this.itemCode = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    rowSelected(item) {
      this.rgpDetails = item;
      this.showHideCostCenterMgmtModal(true);
    },
    showHideCostCenterMgmtModal(flag) {
      this.showAddCostCenterMgmtModal = flag;
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.createdBy.text = item.name;
      this.showUserModal = false;
    },
    openCloseModal(flag) {
      this.showItemModal = flag;
    },
    // selectedItemCode(item) {
    //   this.itemCode.value = item.inventory_item_id;
    //   this.itemCode.text = item.item_code;
    //   this.showItemModal = false;
    // },
    clearSearchParams() {
      this.rgpNumber = '';
      this.createdBy = {
        value: null,
        text: null
      };
      this.itemCode = {
        value: null,
        text: null
      };
      this.costCenter = {
        text: null,
        value: null
      };
      this.wareHouse = {
        text: null,
        value: null
      };
      this.creationDate = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.costCenterMgmtData = [];
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'RGP_INV_ITEMS') {
        this.itemCode = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'COST_CENTER_LIST') {
        this.costCenter = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'WAREHOUSE_LIST') {
        this.wareHouse = {
          text: null,
          value: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
