import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../../store';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addCostCenterMgmt',
  components: {
    DatePicker
  },
  props: ['rgpDetails'],
  data() {
    return {
      showButtonFlag: false,
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      rgpNumber: null,
      createdBy: this.getUserName(),
      itemCode: null,
      remarks: null,
      creationDate: commonHelper.formattedDate(new Date()),
      status: 'Draft',
      costCenter: { text: null, value: null },
      itemFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'item_code'
        },
        {
          key: 'item_desc',
          label: 'Description'
        },
        // {
        //   key: 'cost_center'
        // },
        {
          key: 'cc_qty_sent',
          label: 'Cc Qty. Sent'
        },
        {
          key: 'cc_awb',
          label: 'CC AWB',
          class: 'text-center'
        },
        {
          key: 'warehouse'
        },
        {
          key: 'wh_qty_sent',
          lable: 'Wh Qty Sent'
        },
        {
          key: 'wh_awb',
          lable: 'WH AWB'
        },
        {
          key: 'cc_line_remarks',
          label: 'Comments',
          class: 'text-center'
        }
      ],
      itemData: [
        {
          unsubscribe: null,
          showButtonFlag: false,
          balance: null,
          cc_AWB: null,
          cc_qty_rec: null,
          cc_qty_sent: null,
          inv_item_id: null,
          item_code: null,
          costCenter: null,
          warehouse: null,
          warehouse_id: 0,
          item_desc: null,
          last_update_date: null,
          line_status: null,
          rgp_dtl_id: 0,
          wh_qty_sent: null,
          tempFlag: true,
          addFlag: true,
          cc_qty_sent_isEnable: false,
          cc_qty_rec_isEnable: false,
          cc_AWB_isEnable: false,
          cc_line_remarks_isEnable: false
        }
      ],
      index: null,
      showValueSetModal: false,
      rgpHeaderId: 0,
      selectedIndex: null,
      tempFlag: false
    };
  },
  mounted() {
    if (this.rgpDetails) {
      this.rgpHeaderId = this.rgpDetails.rgp_hdr_id;
      this.showButtonFlag = true;
      this.getCostCenterDataByHdrId(this.rgpHeaderId);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditCostCenterData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getCostCenterDataByHdrId(rgpHdrId) {
      const payload = {
        rgpHdrId: rgpHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('rgpManagement/getCostCenterDataByHdrId', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.rgpNumber = result.rgp_num;
            this.createdBy = result.creator_name;
            this.creationDate = result.creation_date;
            this.remarks = result.cc_remarks;
            this.status = result.status;
            this.rgpHeaderId = result.rgp_hdr_id;
            this.itemData = result.rgp_cc_dtl;
            this.costCenter = result.cost_center;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditCostCenterData() {
      const costDetails = this.itemData.map(elem => {
        return {
          cc_awb: elem.cc_awb,
          cc_line_remarks: elem.cc_line_remarks,
          cc_qty_sent: elem.cc_qty_sent,
          // cost_center_id: elem.cost_center_id,
          cost_center_id: this.costCenter.value,
          inv_item_id: elem.inv_item_id,
          rgp_dtl_id: elem.rgp_dtl_id,
          warehouse_id: elem.warehouse_id
        };
      });
      const payload = {
        cc_remarks: this.remarks,
        le_id: 67,
        rgp_hdr_id: this.rgpHeaderId,
        rgp_cc_dtl: costDetails ? costDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('rgpManagement/addEditCostCenterData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.showButtonFlag = true;
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.rgpNumber = response.data.data.rgp_num;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    updateCostCenterDetails() {
      const payload = {
        modifiedStatus: this.status === 'Draft' ? 'SUBMITTED' : 'CLOSED',
        rgpHdrId: this.rgpDetails.rgp_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('rgpManagement/updateCostCenterDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    addNewRow() {
      if (this.editMode) {
        this.itemData.push({
          balance: null,
          cc_awb: null,
          cc_qty_rec: null,
          cc_qty_sent: null,
          inv_item_id: 0,
          item_code: null,
          cost_center: null,
          cost_center_id: null,
          warehouse_id: null,
          warehouse: null,
          item_desc: null,
          last_update_date: null,
          line_status: null,
          rgp_dtl_id: 0,
          rgp_hdr_id: 0,
          wh_qty_sent: null,
          tempFlag: true,
          addFlag: true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.itemData.splice(index, 1);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.RGP_INV_ITEMS) {
        this.itemData[this.selectedIndex].item_code = item.value_code;
        this.itemData[this.selectedIndex].inv_item_id = item.value_set_dtl_id;
        this.itemData[this.selectedIndex].item_desc = item.item_desc;
      }
      // else if (this.vsetCode === appStrings.VALUESETTYPE.COST_CENTER_LIST) {
      //   this.itemData[this.selectedIndex].cost_center = item.value_code;
      //   this.itemData[this.selectedIndex].cost_center_id =
      //     item.value_set_dtl_id;
      // } 
      else if (this.vsetCode === appStrings.VALUESETTYPE.COST_CENTER_LIST) 
      { this.costCenter.text = item.value_code; this.costCenter.value = item.value_set_dtl_id; }
      else if (this.vsetCode === appStrings.VALUESETTYPE.WAREHOUSE_LIST) {
        this.itemData[this.selectedIndex].warehouse = item.value_code;
        this.itemData[this.selectedIndex].warehouse_id = item.value_set_dtl_id;
      }
      this.itemData = JSON.parse(JSON.stringify(this.itemData));
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'RGP_INV_ITEMS') {
        this.itemData[index].item_code = null;
        this.itemData[index].inv_item_id = null;
        this.itemData[index].item_desc = null;
      }
      //  else if (vsetCode === 'COST_CENTER_LIST') {
      //   this.itemData[index].cost_center = null;
      //   this.itemData[index].cost_center_id = null;
      // } 
      else if (vsetCode === 'COST_CENTER_LIST') {
        this.costCenter = {
          text: null,
          value: null
        };
      }
      else if (vsetCode === 'WAREHOUSE_LIST') {
        this.itemData[index].warehouse = null;
        this.itemData[index].warehouse_id = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
